import AppRouter from "./AppRouter";

const App = () => {
  return (
    <>
      <AppRouter />
    </>
  );
};

export default App;
